@import './fonts/typography.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
  color: #d1d5db;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.aekabg {
  background: rgb(11, 15, 43);
  background: linear-gradient(
    157deg,
    rgba(11, 15, 43, 1) 0%,
    rgba(41, 11, 43, 0.6194852941176471) 53%,
    rgba(11, 15, 43, 1) 100%
  );
}
