/**
 * @license
 * MyFonts Webfont Build ID 4318325, 2022-01-30T09:34:06-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gilroy-UltraLightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-SemiBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-UltraLight by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-RegularItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-MediumItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Medium by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-LightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Light by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-BoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold-italic/
 * Copyright: Copyright � 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 *
 *
 *
 * © 2022 MyFonts Inc
*/

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 600;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroySemiBoldItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyBold/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyRegularItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyMediumItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 200;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyUltraLight/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyLightItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyRegular/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyBoldItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyMedium/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 200;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyUltraLightItalic/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 300;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroyLight/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  font-display: block;
  src: local('Gilroy'), url('webFonts/GilroySemiBold/font.woff2') format('woff2');
}
